<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div class="container">
        <div class="logo">
          <router-link to="/home">
            <img src="/images/logo.png" />
            <span v-html="metatext(translation.title)"></span>
          </router-link>
        </div>
        <div class="navbar">
          <ul class="navbar-nav ml-auto">
            <template v-for="language in languages">
              <li :key="`lang-key-${language}`" v-if="language != lang">
                <router-link class="nav-link" :to="to(language)">{{translation.languages[language]}}</router-link>
              </li>
            </template>
            <li class="nav-item" v-if="hrefs('/login/index')">
              <router-link v-if="!$store.getters.logged" class="nav-link" to="/login">{{translation.login}}</router-link>
              <router-link v-else class="nav-link" to="/profile">{{translation.profile}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script>
export default {
  methods: {
    to(lang) {
      let {name, params, query} = this.$route
      params = Object.assign({}, params, {lang})
      return {name, params, query}
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  cursor: pointer;
}
.logo {
  display: inline-block;
  img {
    height: 80px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: #1a2752;
    font-size: 1.2rem;
    font-weight: 700;
  }
}
.navbar {
  align-self: baseline;
}
</style>