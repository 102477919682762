import minimatch from 'minimatch'
import { translation } from '@content/documents/translation.yml'
import { mapState, mapMutations } from 'vuex'

var mixin = {
    computed: {
        ...mapState({
            lang: state => state.settings.lang,
            documents: state => state.documents
        }),
        languages: function() {
            return Object.keys(translation)
        },
        translation: function() {
            return translation[this.lang]
        }
	},
    methods: {
        document: function({ vue, attributes }, lang) {
            lang = lang || attributes.lang
            this.saveDocument({
                lang, 
                href: attributes.href, 
                data:  {
                    content: vue.component,
                    meta: attributes
                }
            })
        },
        hrefs: function(pattern, lang) {
            lang = lang || this.lang
            let found = []
            for (let href in this.documents[lang]) {
                if (minimatch(href, pattern)) {
                    found.push(this.documents[lang][href])
                }
            }
            if (found.length == 0) return undefined
            if (found.length == 1) return found[0]
            return found
        },
        hres: function(name, lang) {
            lang = lang || this.lang
            return this.documents[lang][name]
        },
        ... mapMutations(['saveDocument'])
    },
}

export default {
    install(Vue) {
        Vue.mixin(mixin)
    }
}