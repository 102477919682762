<template>
    <div>
        <header class="bg-primary py-5 mb-5" :style="{'background-image': `url(${doc.meta.banner || '/images/header-small-bg.jpg'})`}">
            <div v-if="doc.meta.customHeader" class="external" v-html="doc.meta.customHeader">
            </div>
        </header>
    </div>
</template>

<script>
export default {
    props: {
        doc: {
            type: Object,
            default: {
                meta: {}
            }
        }
    }
}
</script>

<style lang="scss" scoped>

header {
    min-height: 250px;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
}

</style>