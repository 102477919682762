<template>
  <div class="card text-black bg-light mb-3">
    <div class="card-header">{{ translation.filter }}</div>
    <div class="card-body">
      <p class="card-text">{{ translation.byIssue }}</p>
      <small class="card-text">
        <router-link to="issues">
          {{ translation.all }}
        </router-link>
        |
        <template v-for="year in years">
          <router-link :key="`year-${year}`" :to="{ query: { year } }">
            {{ year }}
          </router-link>
          |
        </template>
      </small>
    </div>
    <hr />
    <div class="card-body">
      <p class="card-text">{{ translation.byVolume }}</p>
      <div class="row">
        <div class="col">
          <input
            type="number"
            class="form-control"
            :placeholder="translation.volumeNr"
            :min="journal.minVolume"
            :max="journal.maxVolume"
            v-model="volume"
          />
          <small
            class="text-muted"
            v-if="journal.minVolume != journal.maxVolume"
            >({{ translation.from }} {{ journal.minVolume }}
            {{ translation.to }} {{ journal.maxVolume }})</small
          >
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            :placeholder="translation.issueNr"
            v-model="issue"
            :disabled="!volume"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <router-link
            tag="button"
            class="btn btn-primary"
            :to="{ query: { volume, issue } }"
            :disabled="!volume"
            >{{ translation.filter }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "IssueFilter",
  props: ["journal"],
  data: () => ({
    volume: "",
    issue: "",
  }),
  mounted() {
    this.onQueryChange();
  },
  computed: {
    query() {
      return this.$route.query;
    },
    years() {
      let result = [];
      if (
        !this.journal.minYear ||
        !Number(this.journal.minYear) ||
        !this.journal.maxYear ||
        !Number(this.journal.maxYear)
      ) {
        return result;
      }

      let current = Number(this.journal.minYear);
      let end = Number(this.journal.maxYear);

      result.push(current.toString());
      if (current < end) {
        while (current != end) {
          result.push(current.toString());
          current++;
        }
        result.push(end.toString());
      }
      return [...new Set(result)].reverse();
    },
  },
  watch: {
    query(query) {
      this.onQueryChange();
    },
    "journal.minVolume": function() {
      if (this.journal.minVolume == this.journal.maxVolume) {
        this.volume = this.journal.minVolume;
      }
    },
  },
  methods: {
    onQueryChange() {
      if (this.query.volume) {
        this.volume = this.query.volume;
      }
      if (this.query.issue) {
        this.issue = this.query.issue;
      }
      this.loadJournalIssues({ issn: this.journal.issn, query: this.query });
    },
    filterIssues() {
      this.loadJournalIssues({
        issn: this.journal.issn,
        query: {
          volume: this.volume,
          issue: this.issue,
        },
      });
    },
    ...mapActions(["loadJournalIssues"]),
  },
};
</script>

<style lang="scss" scoped>
hr {
  margin-bottom: 0;
}
button {
  margin-top: 15px;
}
</style>
