<template>
    <div v-if="newsItems.length" class="news">
        <h2>{{translation.news}}</h2>
        <hr>
        <div class="card h-20" v-for="item in newsItems" :key="`news-iten-${item.href}`">
            <router-link :to="{name: 'page', params: {href: item.meta.href}}">
                <img :src="item.meta.image" class="card-img-top mt-3" :alt="item.meta.title" v-if="item.meta.image">
            </router-link>
            <div class="card-body">
                <h5 class="card-title">{{item.meta.title}}</h5>
                <p class="card-text">{{item.meta.description}}</p>
            </div>
            <div class="card-footer">
                <router-link :to="{name: 'page', params: {href: item.meta.href}}" class="btn btn-primary">{{translation.findOutMore}}</router-link>
            </div>
        </div>
        <hr>
    </div>
</template>

<script>
export default {
    name: "NewsList",
    computed: {
        newsItems() {
            let items = this.hrefs('/news/*') || []
            return [].concat(items).sort((a,b) => b.meta.order - a.meta.order).slice(0,3)
        }
    }
}
</script>

<style scoped>
img.card-img-top {
    max-height: 100px;
    object-fit: cover;
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>