// import firebase from 'firebase'
import * as firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'


// firebase init goes here
const firebaseConfig =
{
    production: require('@config/firebase-client-sdk.js')
}
firebase.initializeApp(firebaseConfig['production'])

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const admin = firebase;
const storage = firebase.storage();
const currentUser = auth.currentUser;
const storageRef = storage.ref();


window.firebase = firebase

const functions = {
    search: firebase.functions().httpsCallable('search'),
    searchAuthors: firebase.functions().httpsCallable('searchAuthors')
}

auth.languageCode = 'bg'
// date issue fix according to firebase
const settings = {
    //timestampsInSnapshots: true
}
db.settings(settings)

// firebase collections
const collectionPrefix = process.env.NODE_ENV == "development" ? "" : ""
console.log('Running in', collectionPrefix)
const articlesCollection = db.collection(collectionPrefix+'articles')
const journalsCollection = db.collection(collectionPrefix+'journals')
const systemCollection = db.collection(collectionPrefix+'system')
const userCollection = db.collection(collectionPrefix+'users')
const mailCollection = db.collection('mail')

export {
    db,
    auth,
    storage,
    storageRef,
    currentUser,
    articlesCollection,
    systemCollection,
    journalsCollection,
    userCollection,
    mailCollection,
    admin,
    functions
};