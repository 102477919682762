<template>
    <!-- Header -->
    <header class="py-5 mb-5" :style="{'background-image': `url(${doc.meta.banner || '/images/header-bg.jpg'})`}">
        <div v-if="doc.meta.customHeader" class="external" v-html="doc.meta.customHeader">
        </div>
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-lg-12">
                    <!-- <h1 class="display-4 text-dark mt-5 mb-2">Scientific Bulgarian Communications</h1> -->
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    props: ['doc']
}
</script>

<style lang="scss" scoped>

header {
    min-height: 300px;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    h1 {
        text-shadow: 1px 1px #000077;;
    }
}

</style>