<template>
    <div class="card text-black bg-light mb-3">
        <div class="card-header">{{translation.filter}}</div>
        <div class="card-body">
            <p class="card-text">{{translation.search}}</p>
            <div class="form-inline form-group mb-2">
                <input type="text" class="form-control mr-2 mb-1" :placeholder="translation.searchTextHere" v-model="input" v-on:keyup.enter="makeSearch">
                <router-link tag="button"
                     :to="{query: {search: input}}"
                     type="button" class="btn btn-primary mb-1" :disabled="loadingArticles || input.length < minInputLength"
                >
                    <font-awesome-icon icon="search" />
                    {{translation.search}}
                </router-link>
            </div>

            <div v-for="(item, idx) in meta.meta.articleFilters" :key="`article-filter-item-${idx}`" class="form-check form-check-inline">
                <small>
                    <input type="checkbox" class="form-check-input" v-model="fields" :value="item.value" :id="`article-filter-input-${idx}`">
                    <label class="form-check-label" :for="`article-filter-input-${idx}`">{{item.label}}</label>
                </small>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ArticleFilter',
    props: ['journal', 'meta'],
    data () {
        return {
            fields: [],
            minInputLength: 4,
            input: ''
        }
    },
    mounted() {
        this.meta.meta.articleFilters.forEach(item => this.fields.push(item.value))
        this.onQueryChange()
    },
    computed: {
        ... mapGetters(['loadingArticles']),
        search() {
            return this.$route.query.search
        },
        query() {
            return this.$route.query
        }
    },
    watch: {
        query() {
            this.onQueryChange()
        },
    },
    methods: {
        ... mapActions(['doSearch']),
        makeSearch() {
            if(this.input.length < this.minInputLength) return

            this.doSearch({query: this.input, fields: this.fields.join(','), issn: this.journal.issn})
        },
        onQueryChange() {
            if(this.search) {
                this.input = this.search
                this.makeSearch()
            } else {
                this.$store.dispatch('fetchArticles', {issn: this.journal.issn})
            }
        },
    }
}
</script>
