<template>
    <div class="card text-black bg-light mb-3" v-if="journal">
        <div class="card-header">{{translation.browse}}</div>
        <ul class="list-group">
            <router-link 
                tag="li"
                class="list-group-item" 
                v-for="card in meta.meta.availableCards" 
                :key="card.route" 
                :class="{active: card.route==selectedRoute}"
                :to="`/journal/${journal.issn}/${card.route}`"
            >
                <div>{{card.name}}</div>
            </router-link >
        </ul>
    </div>  
</template>

<script>
export default {
    props: ['journal', 'meta', 'selectedRoute']
}
</script>
<style lang="scss" scoped>
.list-group-item {
    cursor: pointer;
}
</style>