<template>
    <div class="card text-black bg-light mb-3">
        <div class="card-header">{{translation.filter}}</div>
        <div class="card-body">
            <p>{{translation.authors}}</p>
            <small class="card-text">
                <router-link @click="loadAuthors" to="authors">
                    {{translation.all}}
                </router-link >
                |
                <router-link v-for="letter in letters" :key="`letter-${letter}`" :to="{query: {letter}}">
                    {{letter}} 
                </router-link>
            </small>
        </div>
        <hr>
        <div class="card-body">
            <p class="card-text">{{translation.search}}</p>
            <div class="form-inline form-group mb-2">
                <input type="text" class="form-control mr-2 mb-1" :placeholder="translation.searchAuthorHere" v-model="input" v-on:keyup.enter="makeSearch">
                <router-link tag="button"
                     :to="{query: {search: input}}"
                     type="button" class="btn btn-primary mb-1" :disabled="loadingJournalData || input.length < minInputLength"
                >
                    <font-awesome-icon icon="search" />
                    {{translation.search}}
                </router-link>
            </div>

            <div v-for="(item, idx) in meta.meta.authorFilters" :key="`author-filter-item-${idx}`" class="form-check form-check-inline">
                <small>
                    <input type="checkbox" class="form-check-input" v-model="fields" :value="item.value" :id="`author-filter-input-${idx}`">
                    <label class="form-check-label" :for="`author-filter-input-${idx}`">{{item.label}}</label>
                </small>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'AuthorsFilter',
    props: ['journal', 'meta'],
    data () {
        return {
            fields: [],
            input: '',
            minInputLength: 4
        }
    },
    mounted() {
        this.meta.meta.authorFilters.forEach(item => this.fields.push(item.value))
        this.onQueryChange()
    },
    computed: {
        letters() {
            const firstLetter = 'A'.charCodeAt(0)
            const lastLetter = 'Z'.charCodeAt(0)
            return Array.apply(undefined, Array(lastLetter - firstLetter + 1)).map(function(x,y) { return String.fromCharCode(y + firstLetter); }).join('')
        },
        firstLetter() {
            return this.$route.query.letter
        },
        search() {
            return this.$route.query.search
        },
        query() {
            return this.$route.query
        },
        ... mapGetters(['loadingJournalData'])
    },
    watch: {
        query(query) {
            this.onQueryChange()
        }
    },
    methods: {
        makeSearch() {
            if(this.input.length < this.minInputLength) return

            this.doSearchAuthors({query: this.input, fields: this.fields.join(','), issn: this.journal.issn})
        },
        loadAuthorsByLetter() {
            this.$store.dispatch('loadJournalAuthors', {issn: this.journal.issn, firstLetter: this.firstLetter})
        },
        loadAuthors() {
            this.$store.dispatch('loadJournalAuthors', {issn: this.journal.issn})
        },
        onQueryChange() {
            if(this.firstLetter) {
                this.loadAuthorsByLetter()
            } else if(this.search) {
                this.input = this.search
                this.makeSearch()
            } else {
                this.loadAuthors()
            }
        },
        ... mapActions(['doSearchAuthors']),
    }
}
</script>
<style lang="scss" scoped>
hr {
    margin-bottom: 0;
}
</style>